angular.module('fingerink')
    .config(function config($stateProvider) {
        $stateProvider.state('sign', {
            url: '/sign/:company/:type/:token',
            views: {
                "content": {
                    controller: 'SignCtrl',
                    templateUrl: 'web/sign/sign.tpl.html',
                    controllerAs: 'controller'
                }
            },
            data: {
                pageTitle: 'Sign Document'
            }
        });
    })
    .controller('SignCtrl', function($rootScope, $scope, signatureService, $stateParams, $timeout, $translate, companyService, $state) {

        var that = this;

        that.token = $stateParams.token;
        that.type = $stateParams.type;
        that.company = $stateParams.company;
//        that.domain = $rootScope.dominio;
        companyService.companyImageIdURL(that.company).then(function (response) {
            that.imageCompanyURL = response.data;
        });

    
        that.texts = {
            Email : [
                {icon:'ti-check-box', text:$translate.instant('Acepta los Términos y Condiciones y la Política de Privacidad')},
                {icon:'ti-search', text:$translate.instant('Haz click en Ver documento')},
                {icon:'ti-import', text:$translate.instant('Haz click en Descargar para guardar una copia del documento')}
            ],
            Fast: [
                {icon:'ti-check-box', text:$translate.instant('Acepta los Términos y Condiciones y la Política de Privacidad')},
                {icon:'ti-search', text:$translate.instant('Haz click en Ver documento')},
                {icon:'ti-check', text:$translate.instant('Revisa el documento y haz click en Firmar para completar el proceso de firma')}
            ],
            Advanced: [
                {icon:'ti-check-box', text:$translate.instant('Acepta los Términos y Condiciones y la Política de Privacidad')},
                {icon:'ti-search', text:$translate.instant('Haz click en Ver documento')},
                {icon:'ti-pencil-alt', text:$translate.instant('Revisa y firma el documento en la pantalla de tu dispositivo')},
                {icon:'ti-check', text:$translate.instant('Haz click en Firmar para completar el proceso de firma')}
            ]
        };
        
        that.aceptar = function () {
            if (!that.privacidad) {
                swal($translate.instant('Atención'), $translate.instant("Debes aceptar la privacidad y los términos y condiciones"), "info");
                return;
            } else {
                signatureService.acceptByToken(that.token).then(function (response) {
                    $state.go('sign'+that.type, {token: that.token });
                }, function (error) {
                    swal($translate.instant('Algo ha ido mal'), $translate.instant("Esta firma ya no está disponible"), "error");
                    $timeout(function () {
                        window.location = "https://" + $rootScope.dominio;
                    }, 5000);
                });
            }
        };
    });
